<template>
  <div class="flex justify-center items-center h-full w-full">
    <video
      ref="videoPlayer"
      controls
      disablePictureInPicture
      loop
      controlsList="nodownload"
      class="flex object-scale-down h-full w-full"
    />
  </div>
</template>

<script>
import Hls from 'hls.js'

export default {
  props: {
    src: {
      type: String,
      required: true
    }
  },

  watch: {
    src: {
      handler (value) {
        this.mountVideo()
      }
    }
  },

  mounted () {
    this.mountVideo()
  },

  methods: {

    mountVideo () {
      if (this.src !== undefined) {
        if (this.src.indexOf('.m3u8') > 0) this.mountHLS()
        else this.mountStandard()
      }
    },

    mountStandard () {
      let element = this.$refs.videoPlayer
      element.src = this.src
    },

    mountHLS () {
      if (Hls.isSupported()) {
        let element = this.$refs.videoPlayer
        let hls = new Hls()
        let src = this.src

        hls.attachMedia(element)

        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          hls.loadSource(src)
        })
      }
    }
  }

}
</script>
